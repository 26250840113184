/**
 * Okta sign in widget
 */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { injectIntl } from 'react-intl';
import envVars from 'env/config';
import messages from '../messages';
import {
  Root,
  ImageContainerColumn,
  OktaWidgetContainerColumn,
  PaperDiv,
  OktaSignInDiv,
  LegalLinksContainer,
  TermsAndConditionsLink,
  PrivacyPolicyLink,
} from './styles';

/**
 * Okta sign in widget
 * @param {*} config Okta Widget configuration
 * @param {function} onSuccess On success function
 * @param {function} onError On error function
 */
function OktaSignInWidget(props) {
  const { config, onSuccess, intl } = props;
  const globalConfig = envVars;
  const { formatMessage } = intl;
  const widgetRef = useRef();
  useEffect(() => {
    if (!config) {
      return false;
    }

    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess);

    return () => widget.remove();
  }, []);

  return (
    <Root container component="main">
      <CssBaseline />
      <ImageContainerColumn item xs={false} sm={4} md={7} />
      <OktaWidgetContainerColumn item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <PaperDiv>
          <OktaSignInDiv ref={widgetRef} />
        </PaperDiv>

        <LegalLinksContainer container>
          <Grid item sm={6}>
            <Typography>
              <TermsAndConditionsLink href={globalConfig.termsAndConditionsUri} target="_blank">
                {formatMessage(messages.termsAndConditions)}
              </TermsAndConditionsLink>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>
              <PrivacyPolicyLink href={globalConfig.privacyAndPolicyUri} target="_blank">
                {formatMessage(messages.privacyAndPolicy)}
              </PrivacyPolicyLink>
            </Typography>
          </Grid>
        </LegalLinksContainer>
      </OktaWidgetContainerColumn>
    </Root>
  );
}

OktaSignInWidget.propTypes = {
  config: PropTypes.object,
  onSuccess: PropTypes.func,
  intl: PropTypes.any.isRequired,
};

export default injectIntl(OktaSignInWidget);
